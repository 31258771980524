$(document).ready(function () {
  getHourly();
  planeUpdate();

  setInterval(function () {
    getHourly();
    planeUpdate();

  }, (60000 * 10));

  setInterval(function () {
    $('.webcam').each(function () {
      this.src = this.src;
    });
  }, 15000);

});

function planeUpdate() {
  $.get("/get_pic.php?url=http://lmac.ca/lmac.ca/camera/FI9803P_00626E586324/snap/", function (data) {
    $('#model-plane-1').attr('src', 'http://lmac.ca/lmac.ca/camera/FI9803P_00626E586324/snap/' + $(data).find('a[href*=".jpg"]:last').attr('href'));
  });

  $.get("/get_pic.php?url=http://lmac.ca/camera2/", function (data) {
    $('#model-plane-2').attr('src', 'http://lmac.ca/camera2/' + $(data).find('a[href*=".jpg"]:last').last().attr('href'));
  });

  return true;
}

function getHourly() {
  $('#hourly').html('');

  $.getJSON('https://www.weatherbug.com/api/forecasts/hourly-periods?count=13&lat=42.98&lng=-81.23&timestamp=' + new Date().getTime(), function (response) {
    var output = '';
    for (var index in response) {
      var record = response[index];

      output += '<tr valign="middle">';

      output += '<td class="align-middle" colspan="4">' + record.AbbreviatedTimeLocalStr + '<span class="float-right"><strong style="font-size:20px">' + record.FeelsLikeDisplay + '</strong> (actual:&nbsp;<strong>' + record.TemperatureDisplay + '</strong>)</span><br>';
      output += '<span class="pl-5 float-left"><img height="30" width="30" src="' + record.IconURLSmall + '"/> <em>' + record.Description + '</em></span></td>';
      //output += '<td class="align-middle"></td>';
      output += '<td class="align-middle">' + record.RelativeHumidity + '%</td>';
      output += '<td class="align-middle"><strong>' + record.AdjustedPrecipProbability + '%</strong> (' + record.PrecipRate + 'mm)';
      if (record.ThunderstormProbability > 0) {
        output += '<br><em class="details">' + record.ThunderstormProbability + '% T-storm prob.</em>';
      }
      output += ' </td>';
      output += '<td class="align-middle">' + record.WindSpeed + 'km/h ' + record.WindDirectionDegreesLabel + '</td>';

      output += '</tr>';
    }
    $('#hourly').html(output);
    $('#weatherTime').html("Last Updated: " + new Date().toLocaleString());
  });
}

var options = {
  // Required: API key
  key: 'yDGR4n3Mi7PyQYIWUoNCpjsuvDZuoIOw', // REPLACE WITH YOUR KEY !!!

  // Put additional console output
  verbose: true,

  // Optional: Initial state of the map
  lat: 42.981089,
  lon: -81.249260,
  zoom: 9,
};

// Initialize Windy API
windyInit(options, windyAPI => {
  // windyAPI is ready, and contain 'map', 'store',
  // 'picker' and other usefull stuff


});